import { Slider } from "@material-ui/core";
import "./App.css";
import Image from "./components/Image";

function App() {
  let sounds = [
    "thunderstorm",
    "wind",
    "campfire",
    "birds",
    "rain",
    "crickets",
    "cafe",
    "farm",
    "forest",
    "bubbles",
    "seaside",
    "snow",
    "keyboard",
    "leaves",
    "stream",
  ];

  const playSound = (name) => {
    var audio = document.getElementById(name);
    const img = document.querySelector(`.${name}-image`);
    const slider = document.querySelector(`.${name}-slider`);

    if (audio.paused) {
      audio.play();
      img.style.opacity = 1;
      slider.style.opacity = 1;
    } else {
      audio.pause();
      img.style.opacity = 0.35;
      slider.style.opacity = 0;
    }
  };

  const setVolume = (name, vol) => {
    document.getElementById(name).volume = vol / 100;
  };
  return (
    <div className="App">
      <div className="header">Peace✌️</div>
      {
        <div className="flex">
          {sounds.map((sound) => (
            <div className="card">
              <audio id={sound} preload="none" loop>
                <source
                  src={`/sounds/${sound}.mp3`}
                  alt="play"
                  type="audio/mp3"
                />
              </audio>
              <Image sound={sound} playSound={playSound} />
              <div className={`${sound}-slider opacity--none`}>
                <Slider
                  className=".vol-slider"
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                  defaultValue={100}
                  onChange={(e, volume) => setVolume(sound, volume)}
                />
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  );
}

export default App;
