import React, { useState } from 'react'
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import "../App.css";

const styles = {
  bounce: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

function Image(props) {
  const [imageLoaded, setimageLoaded] = useState(false)
  const {sound, playSound} = props
  return (
    <StyleRoot>
      <img
        src={`https://peace.mohit.rocks/imgs/${sound}.gif`}
        className={`poster ${sound}-image`}
        onClick={() => playSound(sound)}
        alt={sound}
        loading="lazy"
        title={sound}
        onLoad={() => setimageLoaded(true)}
        style={imageLoaded ? styles.bounce : null}
      />
    </StyleRoot>
  )
}

export default Image
